import React, { useEffect, useState,useRef } from 'react'
import Puff from 'react-loading-icons/dist/esm/components/puff';
import {Link, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Button, Row } from 'react-bootstrap';
import {  FaDashcube, FaPrint, FaTrash } from 'react-icons/fa';
import   '../Customcss/saleEntry.css';
import { Box } from '@mui/system';
import axios from 'axios';
import Swal from 'sweetalert2';
import {  toast } from 'react-toastify';

function EditSaleInvoice() {
    const  {id}  = useParams();
  const [items,setItems]=useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [results,setresutls]=useState([]);
  

  const [sectors,setSectors]=useState([]);
  const [subsectors,setSubSectors]=useState([]);
  const [customer,setCustomer]=useState([]);
  const [salesmanId, setSalesmanId] = useState([]);
  const [manualdate, setManualdate] = useState('');
  const [isClick, setIsClick] = useState(false);
  const inputRef = useRef(null);
 const autocompleteRef = useRef(null);
 const [pBalance, setPbalance] = useState(0);
 const [entercash,setEntercash]=useState(0);
 const [discount,setDiscount]=useState(0);
 const [recievediscount,setRecieveDiscount]=useState(0);
 const [ppadvance,setPPadvance]=useState(0);
 const [recovery,setRecovery]=useState(0);
 const [remaining,setRemaining]=useState(0);
 const [balance,setBalance]=useState(0);
 const [salesTax, setSalesTax] = useState(0);
 const [shipping, setShipping] = useState(0);
 const [salesTaxAmount, setSalesTaxAmount] = useState(0);

 const [totalQty, setTotalQty] = useState(0);
 const [totalUnitSalePrice, setTotalUnitSalePrice] = useState(0);
 const [grandTotal, setGrandTotal] = useState(0);
 const [totalWeight, setTotalWeight] = useState(0);
 const [isCheck, setIsCheck] = useState(localStorage.getItem('radio')?localStorage.getItem('radio'):'1');

 const handleRadioChange = (event) => {
   setIsCheck(event.target.value);
   localStorage.setItem('radio',event.target.value);
   toast('Your Mode Is Switching!', {
     position: "top-right",
     autoClose: 5000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined,
     theme: "light",
     });
 };
 const handleDiscountPercent=(id,event)=>{
setRecieveDiscount(0);
  const newItems = [...items];
  const index = newItems.findIndex(item => item.id === id);
newItems[index].dis_percent=event.target.value;
const subtotaldis=(newItems[index].qty*newItems[index].unitsaleprice)*event.target.value/100;
newItems[index].discounted_amount=parseFloat(subtotaldis).toFixed(2);
newItems[index].grandtotal=parseFloat((newItems[index].qty*newItems[index].unitsaleprice)-(subtotaldis)).toFixed(2);
setItems(newItems);  

}

const handleSubTotalDiscount=(id,event)=>{
  const newItems = [...items];
  const index = newItems.findIndex(item => item.id === id);
newItems[index].discounted_amount=event.target.value;
const discountvalue=event.target.value;
const total=parseFloat(newItems[index].qty*newItems[index].unitsaleprice).toFixed(2);
const disval=total-discountvalue;
const disper=(((total-disval)/total)*100).toFixed(2);
newItems[index].dis_percent=disper;
newItems[index].grandtotal=parseFloat((newItems[index].qty*newItems[index].unitsaleprice)-(discountvalue)).toFixed(2);

setItems(newItems);  

  
}
const handleShipping=(e)=>{
if(e.target.value==='')
{
  
  setShipping(0);
}else{
setShipping(e.target.value);

}


}
const handleFilterCart = (e) => {
  const { value } = e.target;

  const items = document.querySelectorAll(".itembody");

  items.forEach((item) => {
    const nameField = item.querySelector("[name='name']");
    const itemName = nameField.value.toLowerCase();
    
    const shouldShowItem = itemName.indexOf(value.toLowerCase()) > -1;
    
    if (shouldShowItem) {
      item.style.display = "flex";
    } else {
      item.style.display = "none";
    }
  });
}


 const handleEntercash=(e)=>{
  e.preventDefault();
  if(e.target.value===''){
  setEntercash(0);
  }else{
    setEntercash(e.target.value);
  
  }
  
  }
  const handleDiscount=(e)=>{
    e.preventDefault();
    if(e.target.value===''){
      setDiscount(0);
      
    }else{

      if(e.target.value>(parseFloat(grandTotal)+parseFloat(shipping)+parseFloat(salesTaxAmount)))
      {
       toast.error('Discount is greater than total', {
         position: "top-right",
         autoClose: 2000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "light",
         });
   
         setDiscount(0);
   
 
      }else{

        setDiscount(e.target.value);
        setRecieveDiscount(e.target.value);
      }

  
    }
    
    } 

    const handleSalesTax=(e)=>{
      e.preventDefault();
      setSalesTax(e.target.value);
      
      }

 useEffect(() => {
 
    if (inputRef.current) {

      inputRef.current.focus();
    }
  },[items.length]);
  useEffect(()=>{

    axios.get(`/api/getproductsforinvoiceinvoice`).then(res=>{
        setresutls(res.data.products);
         
      });

  },[id])

  useEffect(()=>{
    setIsClick(true);
    axios.get('/sanctum/csrf-cookie').then(response => {
  axios.get(`/api/editinvoice/${id}`).then(res=>{
    
    setSalesmanId(res.data.salesman);
    setSectors(res.data.sector);
    setSubSectors(res.data.subsector);
    setCustomer(res.data.customer);
    setManualdate(res.data.ledgers.manual_date);
    
    setItems(res.data.items);
setEntercash((parseFloat(res.data.ledgers.total_cash)+parseFloat(res.data.ledgers.recieve)));

setPbalance(res.data.prebalance);
setDiscount(res.data.ledgers.discounted_amount);

setRecieveDiscount(res.data.ledgers.discounted_amount);
setSalesTax(res.data.ledgers.sales_tex_percent);
setSalesTaxAmount(res.data.ledgers.sales_tex!==null?res.data.ledgers.sales_tex:0);
setShipping(res.data.ledgers.shipping!==null?res.data.ledgers.shipping:0);
setIsClick(false);
  });
  
    },[id]);
  
  },[id]);

  const handleKeyDown = (e) => {
  
    if (e.key === "Enter") {
      if(autocompleteRef.current){
        autocompleteRef.current.focus();
  
      }
    }
  };
  const handleRemove = (id) => {
    
    setItems(items.filter((item) => item.id !== id));
  }; 
  const handleQtyChange = (id, event) => {
    const newItems = [...items];
    const index = newItems.findIndex(item => item.id === id);
    newItems[index].qty = event.target.value;
 
    const subtotaldis=(newItems[index].qty*newItems[index].unitsaleprice)*newItems[index].dis_percent/100;
    newItems[index].discounted_amount=parseFloat(subtotaldis).toFixed(2);
    newItems[index].grandtotal=parseFloat((newItems[index].qty*newItems[index].unitsaleprice)).toFixed(2);

    if(event.target.value >=0){
      setItems(newItems);

    }
    else{
      return alert('less than 1 not allowed');
    }

  };


  const handleUnitSalePriceChange = (id, event) => {
    const newItems = [...items];
      const index = newItems.findIndex(item => item.id === id);
      newItems[index].unitsaleprice = event.target.value;
      const subtotaldis=(newItems[index].qty*newItems[index].unitsaleprice)*newItems[index].dis_percent/100;
      newItems[index].discounted_amount=parseFloat(subtotaldis).toFixed(2);
      newItems[index].grandtotal=parseFloat((newItems[index].qty*newItems[index].unitsaleprice)-(subtotaldis)).toFixed(2);


      setItems(newItems);
  };
  useEffect(() => {
    
    const qty = items.reduce((acc, item) => {
      const value = parseInt(item.qty);
      return isNaN(value) ? acc : acc + value;
    }, 0);
    setTotalQty(qty);

    const weight = items.reduce((acc, item) => {
      const value = parseFloat(item.weight*item.qty);
      return isNaN(value) ? acc : acc + value;
    }, 0);
    setTotalWeight(weight.toFixed(2));
    const unitSalePrice = items.reduce((acc, item) => {
      const value = parseFloat(item.unitsaleprice);
      return isNaN(value) ? acc : acc + value;
    }, 0);
    setTotalUnitSalePrice(unitSalePrice.toFixed(2));

    const sumgrandtotal = items.reduce((acc, item) => {
      const value = parseFloat(item.unitsaleprice*item.qty);
      return isNaN(value) ? acc : acc + value;
    }, 0);
    setGrandTotal(sumgrandtotal.toFixed(2));

    const totaldiscount = items.reduce((acc, item) => {
      const value = parseFloat(item.discounted_amount);
      return isNaN(value) ? acc : acc + value;
    }, 0);
  
      //setDiscount(totaldiscount.toFixed(2));

if(recievediscount>0){

}else{

if(totaldiscount>discount){
  setDiscount(totaldiscount.toFixed(2));
setRecieveDiscount(0);
  
}else if(totaldiscount<discount){
  setDiscount(totaldiscount.toFixed(2));
  setRecieveDiscount(0);
  
}

}
 

  }, [items,discount,recievediscount]);


 
      
  async function handleSubmit(val) {
    try {
      const {data: {item: newItem}} = await axios.get(`/api/fetchitem/${val.id}`);
      const itemExists = items.find(({id}) => id === newItem.id);
      
      if (itemExists) { 
        toast.error('Item Already Added!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      } else {
        const remqty={
          'remaining':newItem.qty,
          'pro_id':newItem.id,
          'dis_percent':0,
          'discounted_amount':0
           }
            setItems([
              ...items,
              { ...newItem, qty: 1,unitsaleprice:(localStorage.getItem('radio')==='1'?newItem.unitsaleprice:newItem.unitretailprice),...remqty }
            ]);
    
            setSelectedOption(null);
      }
    } catch (error) {
      console.error(error);
    }
  }
  
  
  
     
      


  useEffect(() => {
 
 
    if(parseFloat(entercash) > (parseFloat(grandTotal)-parseFloat(discount)+parseFloat(shipping)+parseFloat(salesTaxAmount))){
      const afterdiscount=parseFloat(pBalance)+parseFloat(grandTotal)-parseFloat(entercash)-parseFloat(discount);
     
      setRemaining(0);
      setPPadvance((parseFloat(grandTotal)-parseFloat(discount)+parseFloat(shipping)+parseFloat(salesTaxAmount)).toFixed(2));
    setRecovery((parseFloat(entercash)-(parseFloat(grandTotal)-parseFloat(discount)+parseFloat(shipping)+parseFloat(salesTaxAmount))).toFixed(2));
    setBalance(afterdiscount.toFixed(2));
    }else{
      const afterdiscount=parseFloat(pBalance)+parseFloat(grandTotal)-parseFloat(entercash)-parseFloat(discount);
     
      setRemaining(parseFloat(parseFloat(afterdiscount)+parseFloat(shipping)+parseFloat(salesTaxAmount)).toFixed(2));

      setPPadvance(parseFloat(entercash));
    setRecovery(0);
    setBalance(afterdiscount.toFixed(2));
    
    }
    setSalesTaxAmount((parseFloat(grandTotal)+parseFloat(shipping))*salesTax/100);
    
      },[entercash,pBalance,discount,items,grandTotal,shipping,salesTax,salesTaxAmount]);


      const handleUpdateReciept=(e)=>{
       
     
      setIsClick(true);
      const data={
       letter:items,
       sector:sectors.id,
       subsector:subsectors.id,
       ppcell:customer.id,
       manualdate:manualdate,
       recovery:recovery,
       ppadvance:ppadvance,
       salesmanid:salesmanId.id,
       sales_tex_percent:salesTax,
       shipping:shipping,
       salestaxamount:salesTaxAmount,
       supergrandtotal:grandTotal,
       discount:discount,
       
       visitor_id:id
        }
     
       if(data.letter.length>0 && data.ppcell>0 && data.manualdate!==''){
      
       
          axios.put('/api/updateinvoice',data).then(res=>{
            
         
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500
            })
            
            
           
      setIsClick(false);
            
            
           
      
            });
          }else{
    
            Swal.fire({
              position: 'top-end',
              icon: 'info',
              title: 'Fill All Fields',
              showConfirmButton: false,
              timer: 1500
            })
            setIsClick(false);
          }
      
      
      }


    return (
        <div className='row fadeInDown animated' style={{padding:'4px'}}>
     
        <div className='col-md-3 col-xl-3 col-lg-3 col-xm-12 col-sm-12 shadows'>
        <div className='col-md-12 col-sm-12 addCustomerBoxes'>
        
        <select className='form-control' value={salesmanId.id} >
        <option>Select Salesman</option>
        <option value={salesmanId.id}>{salesmanId.name}</option>
     
        </select>
        </div>
        
        <div className='col-md-12 col-sm-12 addCustomerBoxes'>
        
        <select className='form-control' value={sectors.id} >
        <option  >Select Sector</option>
        <option  value={sectors.id}>{sectors.name}</option>
       
        </select>
        </div>
        <div className='col-md-12 col-sm-12 addCustomerBoxes'>
        
        <select className='form-control' value={subsectors.id}>
        <option  >Select Subsector</option>
        <option  value={subsectors.id}>{subsectors.name}</option>
        
          
        </select>
        </div>
        
        <div className='col-md-12 col-sm-12 addCustomerBoxes'>
        
        <select className='form-control' value={customer.id} >
        
        <option  value={customer.id}>{customer.name}</option>
        
       
        </select>
        </div>
        
        <div className='col-md-12 col-sm-12 addCustomerBoxes'>
        
        <input type="date" className='form-control' name='manualdate' value={manualdate} onChange={(e)=>setManualdate(e.target.value)}></input>
        </div>
        <div className='col-md-12 col-sm-12 addCustomerBoxes'>
        
        
        </div>
        
        
        <div style={{marginTop:'10px',display:'flex',justifyContent:'space-between'}}>
<div className="radio-label">
        <input
          type="radio"
          name="radio-group"
          value="1"
          checked={isCheck === "1"}
          onChange={handleRadioChange}
        />
        <label>Retail</label>
      </div>
      <div className="radio-label">
        <input
          type="radio"
          name="radio-group"
          value="2"
          checked={isCheck === "2"}
          onChange={handleRadioChange}
        />
        <label>Sale</label>
      </div>
      <div className="radio-label">
        <input
          type="radio"
          name="radio-group"
          value="3"
          checked={isCheck === "3"}
          onChange={handleRadioChange}
        />
        <label>Wholesale</label>
      </div>
  </div>
        
        
        
        
        
        
        </div>
     
        <div className='col-md-9 col-xl-9 col-lg-8 col-sm-9 col-xm-9 shadows'>
        
        <div className='row addSearchBoxes'>
        <Autocomplete
  disablePortal
  id="combo-box-demo"
  value={selectedOption}
  onChange={(event, newValue) => {
    setSelectedOption(newValue);
    handleSubmit(newValue);
  }}
  getOptionLabel={(results) => `${results.name} (${results.alias})`}
  isOptionEqualToValue={(option, value) =>
    option.name === value.name || option.alias === value.alias
  }
  options={results}
  noOptionsText={"No Item Found"}
  renderOption={(props, results) => (
    <Box component="li" {...props} key={results.id}>
      {results.name} 
    </Box>
  )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={selectedOption ? <Puff stroke="red" /> : 'Search Item'}
              variant="outlined"
              className="form-control"
              inputRef={autocompleteRef}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && params.inputProps.value) {
                  // Check if params.inputProps.value is not null or undefined
                  const inputText = params.inputProps.value.toLowerCase();
              
                  // If Enter is pressed and there's text in the input, select the first matching result
                  const matchingOptions = results.filter((option) =>
                    (option.name || '').toLowerCase().indexOf(inputText) !== -1 ||
                    (option.alias || '').toLowerCase().indexOf(inputText) !== -1
                  );
              
                  if (matchingOptions.length > 0) {
                    setSelectedOption(matchingOptions[0]);
                    handleSubmit(matchingOptions[0]);
                  }
                }
              }}
            />
          )}
            
        />
         
        </div>
        <div className='row cartBarHeading' style={{paddingRight:'2px',marginTop:'4px',marginLeft:'2px',marginRight:'2px',borderRadius:'4px'}}>
        <div className='col-2'>
        
        <input className='form-control' placeholder='Name' onKeyUp={(e)=>handleFilterCart(e)}></input>
        </div>
        <div className='col-1'>
        Pieces
        </div>
        <div className='col-1'>
        Weight
        </div>
        <div className='col-1'>
        Rem
        </div>
        <div className='col-2'>
        Rate
        </div>
        <div className='col-1'>
Percent
        </div>
        <div className='col-1'>
Discount
        </div>
        <div className='col-2'>
        Total
        </div>
        <div className='col-1'>
        Remove
        </div>
        </div>
        <div className=' cartBody '>
        {items.length>0?'':<Puff stroke='red'/>}
        {items && items.map((item, index)=> (
          <Row className='g-1 mt-1 ml-2 animated fadeInDown itembody' key={item.id}>
          <div className='col-2 ' >
          
          <TextField
          label="Name"
        
        
         
         name='name'
          size="small"
          type='text'
         
          onKeyDown={(e)=>handleKeyDown(e)}  
          value={item.name} 
        
        />
          </div>
          <div className='col-1'>
          
         <TextField
          label="Qty"
        
          inputRef={index === items.length - 1 ? inputRef : null}
         
         name='qty'
          size="small"
          type='number'
         onChange={(e)=>handleQtyChange(item.id,e)}
          onKeyDown={(e)=>handleKeyDown(e)}  
          value={item.qty} 
        
        />
          </div>
          <div className='col-1' >

         <TextField
          label="weight"
        
        
         
         name='weight'
          size="small"
          type='number'
         
          disabled 
          value={(item.weight*item.qty).toFixed(2)} 
        
        />
          
          </div>
          <div className='col-1'>
         <TextField
          label="remaining"
        
         
         
         name='remaining'
          size="small"
          type='number'
         
          disabled 
          value={parseFloat(item.remaining).toFixed(2)} 
        
        />
         
          </div>
          <div className='col-2'>
          <TextField
          label="unitsaleprice"
        
          onChange={event => handleUnitSalePriceChange(item.id, event)}  onKeyDown={handleKeyDown}
         
         name='unitsaleprice'
          size="small"
          type='number'
         
        
            value={item.unitsaleprice} 
        
        />
          
          </div>
          <div className='col-1'>
          <TextField
          label="Percent"
        
        
         
         name='discount'
          size="small"
          type='number'
         
          onKeyDown={(e)=>handleKeyDown(e)}  
          value={item.dis_percent}
          onChange={event => handleDiscountPercent(item.id, event)} 
        />

          </div>
          <div className='col-1'>
          <TextField
          label="Total"
        
        
         
         name='subtotaldiscount'
          size="small"
          type='number'
         
          onKeyDown={(e)=>handleKeyDown(e)}  
          value={item.discounted_amount} 
          onChange={event => handleSubTotalDiscount(item.id, event)}
        />

          </div>
          <div className='col-2'>
          <TextField
          label="total"
        
         
         name='total'
          size="small"
          type='number'
         
          disabled 
          value={parseFloat(item.unitsaleprice*item.qty).toFixed(2)} 
        
        />
          </div>
          <div className='col-1' style={{borderBottom:'1px solid gray'}}>
         <FaTrash onClick={() => handleRemove(item.id)} style={{cursor:'pointer',color:'red',fontSize:'29px',marginTop:'5px'}}></FaTrash>
          </div> 
          </Row>
         
         ))}
        
        </div>
        <div className='row cartBodyTotal'>
<div className='col-2'>
Total

</div>
<div className='col-1'>
{totalQty}
</div>
<div className='col-1'>
{totalWeight}
</div>
<div className='col-1'>

</div>
<div className='col-2'>
{totalUnitSalePrice}
</div>
<div className='col-2'>
{discount}
</div>
<div className='col-2'>
{grandTotal}
</div>

</div>
        
        
        <div className='row cartButtonsRow1'>
      
       
        <div className='col-2'>
        Discount:
        </div>
        <div className='col-2'>
<input className='form-control' type='number' name='discount' value={discount!==0?discount:''} onChange={handleDiscount}></input>
       
        </div>
        

        <div className='col-2'>
        Shipping:
        </div>
        <div className='col-2'>
        
          
        <input className='form-control' value={shipping!==0?shipping:''} onChange={(e)=>handleShipping(e)} ></input>
        </div>
        <div className='col-2'>
        GST:
        </div>
        <div className='col-2'>
        <input className='form-control' value={salesTax} onChange={handleSalesTax}></input>

        </div> 
        </div>
        <div className='row cartButtonsRow2'>
        <div className='col-2'>
        Paid Amount:
        </div>
        <div className='col-2'>
        <input className='form-control' name='ppadvance' disabled value={ppadvance} ></input>
        </div>
        <div className='col-2'>
        Enter Cash:
        </div>
        <div className='col-2'>
<input className='form-control' type='number' name='entercash' value={entercash} onChange={handleEntercash}></input>
        
        </div>
        
       
        <div className='col-2'>
        Recovery:
        </div>
        <div className='col-2'>
<input className='form-control' name='recovery' disabled value={recovery}></input>
        
        </div>
        </div>
        
       
        
        <div className='row cartButtonsRow4'>
<div className='col-3'>
Pre Balance=<span>{parseFloat(pBalance).toFixed(2)}</span>
</div>

<div className='col-2'>
After Dis=<span>{remaining}</span>
</div>
<div className='col-2'>
GST=<span>{salesTaxAmount}</span>
</div>
<div className='col-2'>
Shipping=<span>{shipping}</span>
</div>

<div className='col-3' style={{position:'relative',textAlign:'start'}}>
Balance=<h1 style={{position:'absolute',top:'-10px',left:'30%',size:'45px',color:'yellow',direction: 'ltr'}}>{parseFloat(parseFloat(balance)+parseFloat(salesTaxAmount)+parseFloat(shipping)).toFixed(2)}</h1>
</div>



    
</div>
        
        <div className='row cartButtonsRow5'>
        <div className='col-4'>
        
        
        <Button className=" btn-lg bt-block" disabled={isClick}  onClick={(e)=>handleUpdateReciept(e)} variant='success' >
             <FaPrint className='mb-1' style={{color:'yellow'}}/>
             {isClick?<Puff stroke="#98ff98"  height={30}   speed={.75}  /> :' Update & Update'} 
            </Button>
        
        </div>
        
        
        
        
        
        
        <div className='col-4 '>
        <Button className=" btn-lg bt-block" variant='info'  >
             <FaPrint className='mb-1' style={{color:'red'}}/> Reset Values
            </Button>
        </div>
        <div className='col-4'>
          <Link to="/">
          <Button className=" btn-lg bt-block" variant='warning'  >
             <FaDashcube className='mb-1' style={{color:'blue'}}/>Dashboard/Home 
            </Button>
        </Link>
        
        </div>
            
        </div>
        
        </div>
       
        
        
            </div>
  )
}

export default EditSaleInvoice


