
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";


function MonthlyChart(props) {
  return (
    <div>
            <LineChart
      width={500}
      height={300}
   
      data={props.data}
      margin={{
        top: 70,
        right: 10,
        left: 5,
        bottom: -50
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="monthname" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="totalsale"
        stroke="green"
        activeDot={{ r: 8 }}
      />
      <Line type="monotone" dataKey="totalcost" stroke="red" />
    </LineChart>
    </div>
  )
}

export default MonthlyChart
