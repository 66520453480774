import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { useStateContext } from "../Context/ContextProvider";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { TextField } from "@mui/material";
function ProductsDetail() {
  const { items, setItems,weburl } = useStateContext();
  const { id } = useParams();
  const stringitems = JSON.parse(items);

  const [products, setProducts] = useState();
  const [category, setCategory] = useState();
  const [norecord, setNoRecord] = useState(false);
  const [isClick, setIsClick] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [modalShow, setModalShow] = useState(false);

  const [completeitemprop, setCompleteItemProp] = useState([]);

  function MyVerticallyCenteredModal(props) {
    const [saleprice, setSalePrice] = useState(
      props.completeitem.unitsaleprice
    );
    const [saleqty, setSaleQty] = useState(1);
    const [discountpercent, setDiscountPercent] = useState(0);
    const [subtotaldiscount, setSubTotalDiscount] = useState(0);
    const [subtotal, setSubtotal] = useState(
      saleqty * props.completeitem.unitsaleprice
    );
    //const [completeitem, setCompleteItem] = useState(props.completeitem);
    const { items } = useStateContext();
    const stringitems = JSON.parse(items);
    const handleInc = () => {
      const updateqty = parseFloat(saleqty) + 1;

      if (updateqty <= props.completeitem.qty) {
        setSaleQty(updateqty);
        setSubtotal(parseFloat(updateqty * saleprice).toFixed(2));
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Store Does Not Have Enough Items",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    };
    const handleDec = () => {
      const updateqty = parseFloat(saleqty) - 1;
      if (updateqty >= 1) {
        setSaleQty(updateqty);
        setSubtotal(parseFloat(updateqty * saleprice).toFixed(2));
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Cannot Go Below 1",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    };

    const handleSubTotalDiscount = (event) => {
      const discountvalue = event.target.value;

      //active it for discount percentage
      //const total=parseFloat(saleqty*saleprice).toFixed(2);
      //const disval=total-discountvalue;
      //const disper=(((total-disval)/total)*100).toFixed(2);
      //const disper=(discountvalue).toFixed(2);

      setDiscountPercent(discountvalue);
      setSubTotalDiscount(discountvalue);
    };

    const handleSaveModel = (e) => {
      e.preventDefault();

      if (stringitems === null) {
        const stringitems = [];
        const remqty = {
          remaining: props.completeitem.qty,
          discountpercent: discountpercent,
          subtotaldiscount: subtotaldiscount,
        };
        setItems([
          ...stringitems,
          {
            ...props.completeitem,
            qty: saleqty,
            unitsaleprice: saleprice,
            ...remqty,
          },
        ]);

        setModalShow(false);
      } else {
        const remqty = {
          remaining: props.completeitem.qty,
          discountpercent: discountpercent,
          subtotaldiscount: subtotaldiscount,
        };
        setItems([
          ...stringitems,
          {
            ...props.completeitem,
            qty: saleqty,
            unitsaleprice: saleprice,
            ...remqty,
          },
        ]);

        setModalShow(false);
      }
    };
    const handleChangeSalePrice = (e) => {
      setSalePrice(e.target.value);
      setSubtotal(parseFloat(saleqty * e.target.value).toFixed(2));
    };
    const handleChangeSaleQty = (e) => {
      const saleqty = e.target.value === "" ? 0 : e.target.value;
      if (parseFloat(saleqty) <= props.completeitem.qty) {
        setSaleQty(e.target.value);

        setSubtotal(parseFloat(saleprice * saleqty).toFixed(2));
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Store Does Not Have Enough Items",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    };
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ backgroundColor: "blue", color: "white" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ Color: "darkblue" }}
          >
            Cart Items
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSaveModel}>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <TextField
                    label="Price"
                    name="saleprice"
                    value={saleprice}
                    type="number"
                    onChange={(e) => handleChangeSalePrice(e)}
                    size="small"
                  />
                </div>
                <div>
                  <TextField
                    label="Subtotal"
                    name="subtotal"
                    value={subtotal}
                    disabled
                    size="small"
                  />
                </div>
              </div>
              <div
                style={{
                  marginTop: "4px",
                  padding: "4px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <FaPlusCircle
                    style={{ fontSize: "40px", color: "red" }}
                    onClick={handleInc}
                  />
                </div>
                <div>
                  <TextField
                    label="Qty"
                    name="saleqty"
                    value={saleqty}
                    type="number"
                    onChange={(e) => handleChangeSaleQty(e)}
                    size="small"
                  />
                </div>
                <div>
                  <TextField
                    label="totaldiscount"
                    name="subtotaldiscount"
                    value={subtotaldiscount}
                    type="number"
                    onChange={(e) => handleSubTotalDiscount(e)}
                    size="small"
                  />
                </div>
                <div>
                  <FaMinusCircle
                    style={{ fontSize: "40px", color: "orange" }}
                    onClick={handleDec}
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ backgroundColor: "red" }} onClick={props.onHide}>
            Close
          </Button>
          <Button onClick={handleSaveModel}>Save</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleSubmitCart = (id) => {
    axios.get(`/api/fetchitem/${id}`).then((res) => {
      if (stringitems === null) {
        setCompleteItemProp(res.data.item);
        setModalShow(true);
      } else {
        if (stringitems.find((i) => i.id === res.data.item.id)) {
          Swal.fire({
            position: "top-end",
            icon: "info",
            title: "Item already added",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          setCompleteItemProp(res.data.item);
          //setSalePrice(res.data.item.unitsaleprice);

          setModalShow(true);
        }
      }
    });
  };
  useEffect(() => {
    setIsClick(false);
    axios.get(`api/fetch/category`).then((res) => {
      setCategory(res.data.category);

      setIsClick(true);
    });
  }, []);

  useEffect(() => {
    setIsClick(false);
    axios
      .post(`api/productdetail/${id}?page=${currentPage}&search=${searchQuery}`)
      .then((res) => {
        setProducts(res.data.data);
        setTotalPages(res.data.last_page);

        if (res.data.data.length === 0) {
          setNoRecord(true);
        } else {
          setNoRecord(false);
        }

        setIsClick(true);
      });
  }, [id, currentPage, searchQuery]);

  const handleFetchProductCategoryWise = (e, catid) => {
    e.preventDefault();
    setIsClick(false);
    axios.get(`api/productdetail/categorywise/${catid}/${id}`).then((res) => {
      setProducts(res.data.data);
      setTotalPages(res.data.last_page);

      if (res.data.data.length === 0) {
        setNoRecord(true);
      } else {
        setNoRecord(false);
      }

      setIsClick(true);
    });
  };

  return (
    <div className="row" style={{ overflowY: "auto" }}>
      <input
        className="form-control"
        placeholder="Search Item"
        value={searchQuery}
        onChange={handleSearchChange}
      ></input>

      <div className="col-md-4" style={{ textAlign:'center'}}>
        <div className="row" style={{justifyContent:'center'}}>
          {category &&
            category.map((item, index) => (
              <div
                className="categoryboxes col-6 "
                onClick={(e) => handleFetchProductCategoryWise(e, item.id)}
                key={item.id}
              >
                {item.name}
              </div>
            ))}
        </div>
      </div>

      <div className="col-md-8">
        <div className="row" style={{justifyContent:'center',marginTop:'3px'}}>
          {norecord ? "No Item Found" : ""}

          {isClick ? (
            products &&
            products.map((item, index) => (
         

<div
className="animated fadeInDown  col-md-2 col-sm-2 col-xs-3 col-4 productcard"
key={item.id}
onClick={() => handleSubmitCart(item.id)}
style={{
  padding: '2px',
  textAlign: 'center',
  margin: '1px',
  height: '120px',
  borderRadius: '5px',
  cursor: 'pointer',
  boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.2)',
  display: 'flex', // Using flexbox
  flexDirection: 'column', // Stack children vertically
  justifyContent: 'space-between' // Space items evenly
}}
>
 <div style={{backgroundColor:'blue',height:'20%',zIndex:'1'}} >
<p style={{ backgroundColor: 'white', fontSize: '16px',color:'red' }}>{item.name.slice(0,40)}</p>
  
  </div> 
<div style={{backgroundColor:'yellow',height:'60%'}}>
  <img
  alt='img'
  style={{height:'100%',width:'100%'}}
  className='img'
  src={`${weburl}${item.image}`}
  
></img>
</div>
<div style={{backgroundColor:'black',height:'20%'}}>
<p style={{ backgroundColor: 'white', fontSize: '16px',color:'red' }}>Price: {item.unitsaleprice}</p>

</div>
</div>


            ))
          ) : (
            <center>Please Wait...</center>
          )}
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((page) => page - 1)}
          className="btn btn-warning btn-sm mr-3"
        >
          Previous
        </button>
        <span>
          {currentPage} of {totalPages}
        </span>
        <button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((page) => page + 1)}
          className="btn btn-danger btn-sm"
        >
          Next
        </button>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        completeitem={completeitemprop}
      />
    </div>
  );
}

export default ProductsDetail;
