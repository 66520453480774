import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaPrint, FaTrash } from 'react-icons/fa';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';

import PrintSaleInvoice from '../Store/PrintSaleInvoice';
import Puff from 'react-loading-icons/dist/esm/components/puff';
import Swal from 'sweetalert2';
import { useStateContext } from '../Context/ContextProvider';


function MyInvoices() {
const [invoices,setInvoices]=useState([]);
const [totalPages, setTotalPages] = useState(1);

const [searchQuery,setSearchQuery]=useState('');
const [currentPage, setCurrentPage] = useState(1);
const [printData, setPrintData] = useState([]);
const [isPrint,setIsPrint]=useState(false);
const [isClick,setIsClick]=useState('');
const [norecord,setNoRecord]=useState(false);
const {userId}=useStateContext();

const handleSearchChange = (event) => {

    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleDelInvoice=(id,index)=>{
    setIsClick(true);
    
const data={

  'id':id
  }
  axios.post(`api/delete/invoice`,data).then(res=>{

    if(res.data.status===200){
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: res.data.message,
        showConfirmButton: false,
        timer: 1500
      })
      
      setIsClick(false);
   
    }else{
    
    
    }
    
    })


  }

useEffect(()=>{
    const data={
'id':userId

    }
    axios.post(`/api/myinvoices?page=${currentPage}&search=${searchQuery}`,data).then(response=>{
      setInvoices(response.data.invoices.data);
      setTotalPages(response.data.invoices.last_page);
     
if((response.data.invoices.data).length===0){

  setNoRecord(true);
}else{
  setNoRecord(false);
}

    })
   
    
   
    


  
  },[currentPage,searchQuery,isClick,userId]);



  

  
const handleReInvoice=(e,id,index)=>{
 
e.preventDefault();

setIsClick(index);
const data={
  'id':id
}

axios.get('/sanctum/csrf-cookie').then(response => {
axios.post('/api/reinvoice/print',data).then(res=>{
  const printDatas={
    letter:res.data.cart,
    dates:res.data.dates,
    salestax:res.data.salestax,
    shipping:res.data.shipping,
    discount:res.data.discount,
    grandtotal:res.data.grandtotal,
    invoiceno:res.data.invoiceno,
    remainingbill:res.data.remainingbill,
    customer:res.data.customer,
    customeraddress:res.data.customeraddress,
    salesman:res.data.salesman,
    payment:res.data.payment,
    totalpay:res.data.totalpay,
    pbalance:res.data.pbalance,
    recovery:res.data.recovery,
    printingtime:res.data.printingtime,
    manualdate:res.data.manualdate,
    detail:res.data.detail,
    imagesrc:res.data.detail
    
          }
          setPrintData(printDatas);
          
          
            setIsPrint(true);



          });
        });



}

useEffect(()=>{


  if(isPrint){

 
    var div = document.getElementById('printablearea');

    // Create a window object.
    var win = window.open('', '', 'height=700,width=700'); // Open the window. Its a popup window.
    win.document.write(div.outerHTML);     // Write contents in the new window.
    win.document.close();
    
  setIsPrint(false);
  setIsClick('');
  }

},[isPrint]);


    return (
   <div className='animated fadeInDown' style={{overflowX:'auto'}}>
<center><h1>All Sale Invoices </h1><hr/></center>
<Row className='g-2'>
  <div className='col-md-4 mb-2'>
  <input type="text" className='form-control col-md' placeholder='Search Item' value={searchQuery} onChange={handleSearchChange} />

  </div>
<div className='col-md-3'>
{invoices.length? '':<Puff stroke='red'/>}  {norecord?'No Matchig Record Found':null}
</div>
 



  
    
</Row>

    
    
    <Table striped bordered hover size="sm" >
    <thead>
        <tr>
        <th>Sr</th>
       
        <th>Invoice No</th>
        <th>Total Amount</th>
        <th>GST/HST</th>
        <th>Shipping</th>
        <th>Discount</th>
        <th>Cash Recieved</th>
  
      
        <th>Date</th>
        <th>Type</th>
      
    <th>Action</th>
        </tr>
    </thead>
  <tbody>

 {invoices && invoices.map((item,index)=>(


 <tr key={item.id}  style={{backgroundColor:item.qty<15?'lightpink':item.hold==='2'?'red':'white'}}
 >

<td>{index+1}</td>


<td>{item.visitor_id}</td>
<td>{item.total}</td>
<td>{item.sales_tex}</td>
<td>{item.shipping}</td>
<td>{item.discount}</td>

<td>{item.cash_received}</td>

<td>{item.invoice_date}</td>

    <td>{item.type}</td>
   
<td>
    <button onClick={(e)=>handleDelInvoice(item.visitor_id,index)} className='btn btn-danger' style={{marginRight:'3px'}}>{isClick===index?<FaTrash style={{marginBottom:'3px'}}/>:<FaTrash  />} </button> 
<button disabled={isClick===index?true:false} onClick={(e)=>handleReInvoice(e,item.visitor_id,index)} className='btn btn-primary' style={{marginLeft:'3px'}}>{isClick===index?<Puff width={15} height={10}/>:<FaPrint />} </button> 
</td>
       
      
       </tr>
      
 ))
    
}</tbody>  

</Table>
 

   
<button
        disabled={currentPage === 1}
        onClick={() => setCurrentPage((page) => page - 1)}

        className='btn btn-danger btn-sm mr-3'
      >
        Previous
      </button>
      <span>{currentPage} of {totalPages}</span>
      <button
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage((page) => page + 1)}
        className='btn btn-danger btn-sm'
      >
        Next 
      </button>

      <div style={{ display: 'none' }} >
{
  
  isPrint  && <PrintSaleInvoice data={printData} />
  
  
  }
  </div>

</div>



  
  )
}

export default MyInvoices
